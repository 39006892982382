const messages = {
  en: {
    balance: 'Balance',
    action: 'Action',
    qty: 'Quantity',
    qtycount: 'Quantity count',
    qtyorder: 'Ordered quantity',
    qtystock: 'Quantity stock',
    qtydiff: 'Difference',
    progress: 'Progress',
    packingprogress: 'Packing progress',
    processby: 'Process by',
    packingby: 'Packed by',
    created: 'Created',
    modified: 'Modified',
    actions: 'Actions',
    backToList: 'Back to list',
    ind_allow_edit: 'Allow editing',
    ind_allow_childs: 'Allow children',
    all: 'All',
    pending: 'Pending',
    complete: 'Complete',
    product: 'Item',
    description: 'Description',
    process: 'Process',
    confirmPrompt: 'Confirm to continue',
    forgotPassword: 'Forgot Password ?',
    helpForgotPassword: 'Enter your email to reset your password',
    forgotPasswordSuccessfully: 'Process completed successfully, shortly you will receive an email with your new password',
    pleaseWait: 'Please Wait',
    login: 'Login',
    signOut: 'Sign out',
    timeReload: 'You have exceeded the maximum idle time.',
    timeReloadSesion: 'You have exceeded the maximum idle time. closed sesion',
    loginSuccessfully: 'You have successfully logged in',
    username: 'username',
    password: 'Password',
    tryAgain: 'Try again',
    submit: 'Submit',
    ok: 'Ok',
    cancel: 'Cancel',
    reload: 'Reload',
    edit: 'Edit',
    height: 'Height',
    width: 'Width',
    length: 'Length',
    volume: 'Volume',

    management: 'Management',
    countTask: 'Count Task',
    countNum: 'No. Count',
    bin: 'Bin',
    bins: 'Bins',
    site: 'Site',
    sites: 'Sites',
    code: 'Code',
    name: 'Name',
    address: 'Address',
    city: 'City',
    estate: 'Estate',
    country: 'Country',
    date: 'Date',
    support: 'Support',
    dashboard: 'Dashboard',
    search: 'Search',
    tag: 'Tag',
    tags: 'Tags',
    filterOptions: 'Filter options',
    status: 'Status',
    selectOption: 'Open this select menu',
    historyTransaction: 'History Transaction',
    numlines: 'Num. lines',
    document: 'Document',
    documentType: 'Document type',
    documentAssigned: 'This order is beeing processed by the user <USERNAME>. Continue anyway?',
    shippingDocuments: 'Shipping documents',
    shippingDocumentDetail: 'Shipping document',
    confirmDocument: 'Confirm document',
    delivery_company: 'Delivery company',
    delivery_companies: 'Delivery companies',
    deliveryDocument: 'Delivery document',
    deliveryNoMethodErrorValidate: 'Please select a shipping method',
    deliveryNoRateErrorValidate: 'The delivery rate is zero. Continue anyway?',
    deliveryPackingList: 'See packing document',
    deliveryPackingLabel: 'See packaging labels',
    deliveryPackingLabelRefresh: 'See packaging labels refresh',
    deliveryPackingLabelRemove: 'Reset delivery',
    deliveyQuoteDocument: 'Quote dispatch of the document',
    company: 'Company',
    companies: 'Companies',
    company_code: 'Company code',
    company_name: 'Company name',
    service_code: 'Service code',
    service_name: 'Service name',
    country_code: 'Country code',
    ind_packing_consolidated: 'Ind packing consolidated',
    ind_packinglist: 'Ind packing list',
    ind_pkglabels: 'Ind pkglabels',
    quote_endpoint: 'Quote endpoint',
    guide_endpoint: 'Guide endpoint',
    tracking_endpoint: 'Tracking endpoint',
    autoPacking: 'Auto Packing',
    confirmPacking: 'Confirm Packing',
    cancelPackingAutDocument: 'Cancel Auto Picking',
    packing: 'Packing',
    picking: 'Picking',
    packingBalanceError: 'Please enter the requested information.',
    packingNoItemError: 'Please fill at least one record.',
    packingNoItemErrorValidate: 'Some of the records exceed the allowed amount. please check again.',
    packingDocument: 'Packing Document',
    packingDocumentPending: 'Packing Pendiente',
    packingDocumentComplete: 'Packed',
    packageType: 'Package type',
    qtyPicked: 'Qty picked',
    qtyPendingPack: 'Qty Pending to pack',
    qtyPendingPick: 'Qty Pending to pick',
    qtyToPack: 'Qty. to pack',
    qtyPackage: 'Qty Package',
    package: 'Package',
    num_packages: 'Package(s)',
    package_title: '# Packages',
    weight: 'Weight',
    methoddelivery: 'Method delivery',
    tracking: 'Tracking',
    shipTo: 'Ship to',

    historyDocument: 'Document history',
    apply: 'Apply',
    reset: 'Reset',
    accountSettings: 'Account settings',
    language: 'Language',
    spanish: 'Spanish',
    english: 'English',
    layoutBuilder: 'Layout builder',
    craft: 'Crafted',
    pages: 'Pages',
    myProfile: 'My profile',
    profile: 'Profile',
    profileOverview: 'Overview',
    projects: 'Projects',
    campaigns: 'Campaigns',
    documents: 'Documents',
    connections: 'Connections',
    wizards: 'Wizards',
    horizontal: 'Horizontal',
    vertical: 'Vertical',
    account: 'Account',
    accountOverview: 'Overview',
    settings: 'Settings',
    authentication: 'Authentication',
    basicFlow: 'Basic Flow',
    signIn: 'Sign-in',
    signUp: 'Sign-up',
    passwordReset: 'Password reset',
    error404: 'Error 404',
    error500: 'Error 500',
    apps: 'Apps',
    chat: 'Chat',
    privateChat: 'Private chat',
    groupChat: 'Group chat',
    drawerChat: 'Drawer chat',
    widgets: 'Widgets',
    widgetsLists: 'Lists',
    widgetsStatistics: 'Statistics',
    widgetsCharts: 'Charts',
    widgetsMixed: 'Mixed',
    widgetsTables: 'Tables',
    widgetsFeeds: 'Feeds',
    changelog: 'Changelog',
    docsAndComponents: 'Docs & components',
    megaMenu: 'Mega Menu',
    exampleLink: 'Example link',
    modals: 'Modals',
    general: 'General',
    inviteFriends: 'Invite friends',
    viewUsers: 'View users',
    upgradePlan: 'Upgrade plan',
    shareAndEarn: 'Share & earn',
    forms: 'Forms',
    newTarget: 'New target',
    new: 'New',
    newCard: 'New card',
    newAddress: 'New address',
    createAPIKey: 'Create API key',
    twoFactorAuth: 'Two factor auth',
    createApp: 'Create app',
    createAccount: 'Create account',
    documentation: 'Documentation',
    components: 'Components',
    resources: 'Resources',
    activity: 'Activity',
    customers: 'Customers',
    gettingStarted: 'Getting started',
    customersListing: 'Customers listing',
    customerDetails: 'Customers details',
    calendarApp: 'Calendar',
    subscriptions: 'Subscriptions',
    getStarted: 'Getting Started',
    subscriptionList: 'Subscription list',
    addSubscription: 'Add subscription',
    viewSubscription: 'View subscription',
    titleBinChange: 'View titleBinChange',
    error_please_input: 'Please input field',
    error_please_select: 'Please select field',
    users: 'Users',
    identification: 'Id',
    user: 'Username',
    email: 'Email',
    rol: 'Rol',
    active: 'Active',
    confirmPassword: 'Confirm Password',
    erpUserCode: 'ERP user code',
    fullName: 'Full name',
    registerUser: 'Register user',
    resetPassword: 'Reset password',
    helpRegisterUser: 'Please complete the form',
    wrongToken: 'You dont have access code or its invalid!',
    of: 'of',
    processAction: 'Process',
    packageSummary: 'Package summary',
    lot_sn: 'Lot / Serial',
    lot: 'Lot',
    numBoxes: 'Box(es)',
    passwordIncorrect: 'Password incorrect',
    nextDocument: 'Next document',
    notes: 'Notes',
    prioritize: 'Prioritize',
    pickingFinished: 'Picking finished',
    sendErp: 'Send To ERP',
    close: 'Close',
    phone: 'Phone',
    postalCode: 'Postal code',
    rate: 'Tarifa',
    yes: 'Sí',
    no: 'No',
    revertDocument: 'Revert document confirmation',
    revertPackingDocument: 'Reopen packing',
    revertPickingDocument: 'Reopen picking',
    measures: 'Measures',
    qtyPackageshort: 'Packed',
    packageshort: 'Pack.',
    packageTypeshort: 'Type',
    volumeshort: 'K/V',
    weightshort: 'Weight',
    measuresshort: 'Meas.',
    removeItemPackage: 'Remove item from package',
    binshort: 'Bin',
    qtyshort: 'Qty',
    packingFinished: 'Packing finished',
    assigned: 'Assigned',
    unassigned: 'Not Assigned',
    shippingList: 'Orders in progress',
    countTaskList: 'Count Task in progress',
    dispatchList: 'Shipment in progress',
    receivingFinished: 'Receiving finished',
    packages: 'Packages',
    unit: 'Unit',
    integrationControl: 'Integrations control',
    batch: 'Batch',
    type: 'Type',
    direction: 'Direction',
    refnumber: 'Reference',
    request_data: 'Request',
    response_data: 'Response',
    resend: 'Resend',
    reverse: 'Reverse',
    info: 'Info',
    save: 'Save',
    contact: 'Contact',
    editDeliveryContact: 'Edit Delivery Contact',
    tracking_num: 'Tracking num.',
    completedon: 'Completed on',
    completedby: 'Completed by',
    deliveredon: 'Delivered on',
    deliveredby: 'Delivered by',
    confirmPickUp: 'Confirm pick up',
    rollbackPickingDocument: 'Reopen picking',
    rollbackPackingDocument: 'Reopen packing',
    packingNoTypeErrorValidate: 'The package type is not selected. please check again',
    deliveryNoCompanyErrorValidate: 'Please select a delivery company',
    deliveryNoTrackingNumberErrorValidate: 'Please enter the tracking number',
    trackingNumber: 'Tracking number',

    enlisting: 'Enlisting',
    castIn: 'Cast in',
    delivered: 'Delivered',
    confirmDelivery: 'Confirm delivery',
    box: 'Box(es)',
    boxProcessed: 'Processed Box(es)',
    boxPendient: 'Pending Box(es)',
    documentDelivery: 'Document delivery',
    accept: 'Accept',
    deliveryList: 'Deliveries in progress',
    editBatch: 'Edit Batches',
    applyMeasuresToAllEqualBoxes: 'Apply measures to all equal boxes?',
    print: 'Print',
    value: 'Value',
    qtyOrders: 'Qty orders',
    pickTime: 'Picking time (hh:mm)',
    itemsPicked: 'Qty lines',
    packTime: 'Packing time (hh:mm)',

    linePrice: 'Unit price',
    discount: 'Discount',
    tax: 'Tax',
    worth: 'Worth',
    remove: 'Remove',
    invoice_oc: 'Invoice PO',
    productshort: 'Prod.',
    lot_snshort: 'Lot/Sn',
    reubicado: 'Relocated',
    reason: 'Reason',
    copyTo: 'Cc',
    notificationHomologacion: 'Notification of approval products',
    reservedInventory: 'Inventory reserve',
    bincode: 'Bincode',
    expDate: 'Exp. date',
    cartaCompromiso: 'Letter of engagement',
    maxLot: 'Maximum batch quantity per product',
    greaterExpDateMonths: 'Greater exp. date (months)',
    stock: 'Stock',
    available: 'Available',
    stockAvailable: 'Available stock',
    dispatchUnit: 'Dispatch unit',
    vehiclePlate: 'Vehicle Plate',
    reclassification: 'Reclassification',
    send: 'Send',
    add: 'Add',
    new_a: 'New',
    method: 'Method',
    removal: 'Removal',
    entry: 'Entry',
    reclassificationErrorValidate: 'Please enter valid data',
    startDelivery: 'Start delivery',
    countingList: 'Countings in progress',
    sendDocuments: 'Send to print queue',
    administration: 'Administration',
    combineDelivery: 'Combine delivery',
    combineDeliveryQuote: 'Quote dispatch of the combined documents',
    combineDeliveryConfirm: 'Dispatch confirm of the combined documents',
    brand: 'Brand',
    note: 'Note',
    anomalies: 'Anomalies',
    destination: 'Destination',
    successResponse: 'Process done correctly.',
    export: 'Export',
    percentage: 'Percentage',
    data_error: 'Data with errors',
    data_ok: 'Data ok',
    today: 'Today',
    week: 'Week',
    month: 'Month',
    quarter: 'Quarter',
    changeTo: 'Change To',
    products: 'Products',
    productsShort: 'Prods',
    qtycountshort: 'Count',
    qtystockshort: 'Stock',
    line: 'Line',
    qtydiffshort: 'Diff',
    addCountCycle: 'Add count cycle',
    subCountCycle: 'Remove count cycle',
    closeCounting: 'Close counting',
    revertCountingDocument: 'Revert counting document',
    vlrstock: 'Stock value',
    vlrcount: 'Counted value',
    vlrdiff: 'Difference value',
    COUNT: 'Count',
    set_solution_binchange: 'Binchange',
    set_solution_entry: 'Entry',
    cycle: 'Cycle',
    differences: 'Differences',
    updateCountingMirror: 'Update counting mirror',
    syncErp: 'Sync ERP',
    textSyncErp: 'The inventory for <SITE> will sync soon, please wait a few minutes',
    period: 'Period'
  }
}

export default messages
