const messages = {
  es: {
    balance: 'Balance',
    action: 'Acción',
    qty: 'Cantidad',
    qtycount: 'Cant. contada',
    qtyorder: 'Cant. pedida',
    qtystock: 'Cant. inventario',
    qtydiff: 'Diferencia',
    qtyordershort: 'Pedido',
    progress: 'Progreso',
    packingprogress: 'Progreso Empaque',
    processby: 'Procesado por',
    packingby: 'Empacado por',
    created: 'Creado',
    modified: 'Modificado',
    actions: 'Acciones',
    backToList: 'Volver a la lista',
    ind_allow_edit: 'Permitir editar',
    ind_allow_childs: 'Permitir hijos',
    all: 'Todos',
    pending: 'Pendiente',
    complete: 'Completado',
    product: 'Artículo',
    process: 'Procesos',
    confirmPrompt: 'Confirme para continuar',
    forgotPassword: '¿Has olvidado tu contraseña?',
    helpForgotPassword: 'Ingrese su correo electrónico para restablecer su contraseña',
    forgotPasswordSuccessfully: 'Proceso realizado con éxito, en breve recibirá un email con su nueva contraseña',
    pleaseWait: 'Espere por favor',
    login: 'Ingresar',
    signOut: 'Cerrar sesión',
    timeReload: 'Has superado el tiempo máximo de inactividad. Presione OK para continuar',
    timeReloadSesion: 'Has superado el tiempo máximo de inactividad. Su sesion sera cerrada.',
    loginSuccessfully: 'Has iniciado sesión correctamente',
    username: 'Nombre de usuario',
    password: 'Contraseña',
    tryAgain: 'Intentar otra vez',
    submit: 'Enviar',
    ok: 'Ok',
    cancel: 'Cancelar',
    reload: 'Recargar',
    edit: 'Editar',
    height: 'Alto (cm)',
    width: 'Ancho (cm)',
    length: 'Largo (cm)',
    volume: 'Kilo/Volumen',

    management: 'Administración',
    countTask: 'Tarea de conteo',
    countNum: 'No. conteo',
    bin: 'Ubicación',
    bins: 'Ubicaciones',
    site: 'Bodega',
    sites: 'Bodegas',
    code: 'Código',
    name: 'Nombre',
    address: 'Dirección',
    city: 'Ciudad',
    estate: 'Departamento',
    country: 'País',
    date: 'Fecha',

    support: 'Soporte',
    dashboard: 'Tablero',
    search: 'Buscar',
    tag: 'Etiqueta',
    tags: 'Etiquetas',
    filterOptions: 'Opciones de filtro',
    status: 'Estado',
    selectOption: 'Abrir este menú de selección',

    historyTransaction: 'Historial de transacciones',
    numlines: 'Num. líneas',
    document: 'Documento',
    documentType: 'Tipo de documento',
    documentAssigned: 'Esta orden esta siendo procesada por el usuario <USERNAME>. Desea continuar?',
    shippingDocuments: 'Despachos',
    shippingDocumentDetail: 'Documento de despacho',
    confirmDocument: 'Confirmar documento',
    delivery_company: 'Tansportadora',
    delivery_companies: 'Tansportadoras',
    deliveryDocument: 'Despachar documento',
    deliveryNoMethodErrorValidate: 'Por favor seleccionar un método de despacho',
    deliveryNoRateErrorValidate: 'La tarifa de despacho es cero. Desea continuar?',
    deliveryPackingList: 'Ver documento de empaque',
    deliveryPackingLabel: 'Ver rótulos de empaque',
    deliveryPackingLabelRefresh: 'Actualizar rótulos de empaque',
    deliveryPackingLabelRemove: 'Eliminar despacho',
    deliveyQuoteDocument: 'Cotizar despacho del documento',

    company: 'Empresa',
    companies: 'Empresas',
    company_code: 'Código empresa',
    company_name: 'Nombre empresa',
    service_code: 'Código del servicio ',
    service_name: 'Nombre del servicio',
    country_code: 'Código del país',
    ind_packing_consolidated: 'Empaque consolidado',
    ind_packinglist: 'Lista de empaque',
    ind_pkglabels: 'Rotulos de empaque',
    quote_endpoint: 'URL para cotizar',
    guide_endpoint: 'URL para crear remesa',
    tracking_endpoint: 'URL para consultar remesa',

    autoPacking: 'Auto Empacar',
    confirmPacking: 'Confirmar empaque',
    cancelPackingAutDocument: 'Cancelar empaque automatico',
    packing: 'Empacado',
    picking: 'Recolección',
    packingBalanceError: 'Por favor ingrese la información solicitada.',
    packingNoItemError: 'Por favor ingrese al menos un registro.',
    packingNoItemErrorValidate: 'Alguno de los registros supera la cantidad permitida. por favor verifique nuevamente.',
    packingDocument: 'Empacar documento',
    packingDocumentPending: 'Artículos pendientes por empacar',
    packingDocumentComplete: 'Artículos empacados',
    packageType: 'Tipo de empaque',
    qtyPicked: 'Cant. alist.',
    qtyPickedshort: 'Alist.',
    qtyPendingPack: 'Pend. por empacar',
    qtyPendingPick: 'Pend. por alistar',
    qtyPendingPickshort: 'Pend.',
    qtyToPack: 'Cant. a empacar',
    qtyPackage: 'Cant. empacada',
    package_title: '# Paquetes',
    package: 'Paquete',
    num_packages: 'Paquete(s)',
    weight: 'Peso (Kg)',
    methoddelivery: 'Metodo de envio',
    tracking: 'Rastreo',
    shipTo: 'Enviar a',

    historyDocument: 'Historial documento',
    apply: 'Aplicar',
    reset: 'Reiniciar',
    accountSettings: 'Configuraciones de la cuenta',
    language: 'Idioma',
    spanish: 'Español',
    english: 'Inglés',
    layoutBuilder: 'Constructor de maquetación',
    craft: 'Elaborado',
    pages: 'Paginas',
    myProfile: 'Mi Perfil',
    profile: 'Perfil',
    profileOverview: 'Descripción general',
    projects: 'Proyectos',
    campaigns: 'Campañas',
    documents: 'Documentos',
    connections: 'Conexiones',
    wizards: 'Magos',
    horizontal: 'Horizontal',
    vertical: 'Vertical',
    account: 'Tercero',
    accountOverview: 'Descripción general',
    settings: 'Ajustes',
    authentication: 'Autenticación',
    basicFlow: 'Flujo básico',
    signIn: 'Registrarse',
    signUp: 'Inscribirse',
    passwordReset: 'Restablecimiento de contraseña',
    error404: 'Error 404',
    error500: 'Error 500',
    apps: 'Aplicaciones',
    chat: 'Chat',
    privateChat: 'Chat privado',
    groupChat: 'Grupo de chat',
    drawerChat: 'Chat del cajón',
    widgets: 'Widgets',
    widgetsLists: 'Liza',
    widgetsStatistics: 'Estadísticas',
    widgetsCharts: 'Gráficos',
    widgetsMixed: 'Mezclada',
    widgetsTables: 'Mesas',
    widgetsFeeds: 'Alimenta',
    changelog: 'Registro de cambios',
    docsAndComponents: 'Documentos & Componentes',
    megaMenu: 'Mega menú',
    exampleLink: 'Enlace de ejemplo',
    modals: 'Modales',
    general: 'General',
    inviteFriends: 'Invitar A Amigos',
    viewUsers: 'Ver Usuarios',
    upgradePlan: 'Plan De Actualización',
    shareAndEarn: 'Compartir Y Ganar',
    forms: 'Formas',
    new: 'Nuevo',
    newTarget: 'Nuevo Objetivo',
    newCard: 'Nueva Tarjeta',
    newAddress: 'Nueva Direccion',
    createAPIKey: 'Crea Clave De Api',
    twoFactorAuth: 'Dos Factores',
    createApp: 'Crear Aplicacion',
    createAccount: 'Crear Una Cuenta',
    activity: 'Actividad',
    documentation: 'Documentación',
    components: 'Componentes',
    resources: 'Recursos',
    customers: 'Clientes',
    gettingStarted: 'Empezando',
    customersListing: 'Listado De Clientes',
    customerDetails: 'Detalles De Los Clientes',
    calendarApp: 'Calendario',
    subscriptions: 'Suscripciones',
    getStarted: 'Empezando',
    subscriptionList: 'Lista De Suscripción',
    addSubscription: 'Añadir Suscripción',
    viewSubscription: 'Suscripción',

    titleBinChange: 'Cambio de Ubicación',

    error_please_input: 'Por favor ingrese el campo',
    error_please_select: 'Por favor seleccione el campo',

    users: 'Usuarios',
    identification: 'Identificación',
    user: 'Usuario',
    email: 'Email',
    rol: 'Rol',
    active: 'Activo',
    confirmPassword: 'Confirme su contraseña',
    erpUserCode: 'Código usuario en ERP',
    fullName: 'Nombre completo',

    registerUser: 'Registro de usuario',
    resetPassword: 'Cambio de contraseña',
    helpRegisterUser: 'Ingrese los datos para completar el formulario',
    wrongToken: 'No tiene código de acceso o ya fue usado!',
    of: 'de',
    processAction: 'Procesar',
    packageSummary: 'Consolidado de empaque',
    lot_sn: 'Lote / Serial',
    lot: 'Lote',
    numBoxes: 'Caja(s)',
    passwordIncorrect: 'La contraseña no coincide',
    nextDocument: 'Doc. siguiente',
    notes: 'Notas',
    description: 'Descripción',
    prioritize: 'Priorizar',
    pickingFinished: 'Recolección terminada',
    sendErp: 'Enviar al ERP',
    close: 'Cerrar',
    phone: 'Teléfono',
    postalCode: 'Código postal',
    rate: 'Tarifa',
    yes: 'Sí',
    no: 'No',
    revertDocument: 'Deshacer confirmación del documento',
    revertPackingDocument: 'Reabrir empaque del documento',
    revertPickingDocument: 'Reabrir alistamiento del documento',
    measures: 'Medidas',
    qtyPackageshort: 'Empac.',
    packageshort: 'Paq.',
    packageTypeshort: 'Tipo',
    volumeshort: 'K/V',
    weightshort: 'Peso',
    measuresshort: 'Med.',
    removeItemPackage: 'Eliminar item del empaque',
    binshort: 'Ubi.',
    qtyshort: 'Cant',
    packingFinished: 'Empaque terminado',
    assigned: 'Asignado',
    unassigned: 'No Asig.',
    shippingList: 'Pedidos en proceso',
    countTaskList: 'Tareas de conteo en proceso',
    dispatchList: 'Despachos en proceso',
    receivingFinished: 'Recepción terminada',
    packages: 'Empaques',
    unit: 'Unidad',
    integrationControl: 'Control de integraciones',
    batch: 'Lote',
    type: 'Tipo',
    direction: 'Dirección',
    refnumber: 'Referencia',
    request_data: 'Petición',
    response_data: 'Respuesta',
    resend: 'Reenviar',
    reverse: 'Reversar',
    info: 'Info',
    save: 'Guardar',
    contact: 'Contacto',
    editDeliveryContact: 'Editar Datos del despacho',
    tracking_num: 'Remesa',
    completedon: 'Fecha completado',
    completedby: 'Completado por',
    deliveredon: 'Fecha despacho',
    deliveredby: 'Despachado por',
    confirmPickUp: 'Confirmar recogida',
    rollbackPickingDocument: 'Reabrir alistamiento',
    rollbackPackingDocument: 'Reabrir empaque',
    packingNoTypeErrorValidate: 'Seleccione el tipo de empaque e intente nuevamente',
    deliveryNoCompanyErrorValidate: 'Por favor seleccionar una empresa transportadora',
    deliveryNoTrackingNumberErrorValidate: 'Ingrese el número de guía',
    trackingNumber: 'Número de guía',

    enlisting: 'Alistando',
    castIn: 'En reparto',
    delivered: 'Entregado',
    confirmDelivery: 'Confirmar despacho',
    box: 'Caja(s)',
    boxProcessed: 'Caja(s) Procesadas',
    boxPendient: 'Caja(s) Pendientes',
    documentDelivery: 'Entrega de documento',
    accept: 'Aceptar',
    deliveryList: 'Entregas en proceso',
    editBatch: 'Editar lotes',
    applyMeasuresToAllEqualBoxes: 'Aplicar medidas a todas las cajas iguales?',
    print: 'Imprimir',
    value: 'Valor',
    qtyOrders: 'Cant. Ped.',
    pickTime: 'Alist. (hh:mm)',
    itemsPicked: 'Cant. Líneas',
    packTime: 'Empaq. (hh:mm)',

    linePrice: 'Precio unitario',
    discount: 'Descuento',
    tax: 'Impuestos',
    worth: 'Valor',
    remove: 'Eliminar',
    invoice_oc: 'Número de factura en la entrada de mercancía',
    productshort: 'Art.',
    lot_snshort: 'Lot/Se',
    reubicado: 'Reubicado',
    reason: 'Razón',
    copyTo: 'Con copia a',
    notificationHomologacion: 'Notificación de productos homologación',
    reservedInventory: 'Inventario reservado',
    bincode: 'Ubicación',
    expDate: 'Vencimiento',
    cartaCompromiso: 'Carta de compromiso',
    maxLot: 'Máxima cantidad de lote por producto',
    greaterExpDateMonths: 'mayor fecha de caducidad (meses)',

    stock: 'Inventario',
    available: 'Disponible',
    stockAvailable: 'Inventario disponible',
    dispatchUnit: 'Unidad de despacho',
    vehiclePlate: 'Placa del vehículo',
    reclassification: 'Reclasificación',
    send: 'Enviar',
    add: 'Agregar',
    new_a: 'Nueva',
    method: 'Método',
    removal: 'Salida',
    entry: 'Entrada',
    reclassificationErrorValidate: 'Por favor ingrese datos válidos',
    startDelivery: 'Comenzar recorrido',
    countingList: 'Conteos en proceso',
    sendDocuments: 'Enviar a cola de impresión',
    administration: 'Administración',
    combineDelivery: 'Combinar despachos',
    combineDeliveryQuote: 'Cotizar despacho de los documentos combinados',
    combineDeliveryConfirm: 'Confirmar despacho de los documentos combinados',
    brand: 'Marca',
    note: 'Nota',
    anomalies: 'Anomalías',
    destination: 'Destino',
    successResponse: 'Proceso realizado correctamente.',
    export: 'Exportar',
    percentage: 'Porcentaje',
    data_error: 'Datos con error',
    data_ok: 'Datos ok',
    today: 'Hoy',
    week: 'Semana',
    month: 'Mes',
    quarter: 'Trimestre',
    changeTo: 'Cambiar a',
    products: 'Productos',
    productsShort: 'Prods',
    qtycountshort: 'Conteo',
    qtystockshort: 'Inven.',
    line: 'Línea',
    qtydiffshort: 'Diff',
    addCountCycle: 'Agregar ciclo',
    subCountCycle: 'Quitar ciclo',
    closeCounting: 'Cerrar conteo',
    revertCountingDocument: 'Reversar documento de conteo',
    vlrstock: 'Valor inventario',
    vlrcount: 'Valor contado',
    vlrdiff: 'Valor diferencia',
    COUNT: 'Conteo',
    set_solution_binchange: 'Cambio de ubicación',
    set_solution_entry: 'Entrada de inventario',
    cycle: 'Ciclo',
    differences: 'Diferencias',
    updateCountingMirror: 'Actualizar espejo de conteo',
    syncErp: 'Sincronizar ERP',
    textSyncErp: 'El inventario de <SITE> se sincronizara pronto, espere unos minutos',
    period: 'Periodo'
  }
}

export default messages
